import React, { ReactNode, useEffect, useState } from "react";
import { ColorPaletteProp } from '@mui/joy/styles';

import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import InfiniteScroll from 'react-infinite-scroll-component'

// icons
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from "dayjs";
import { Stack } from "@mui/material";
import Table from "@mui/joy/Table";
import { FlowSession, useArchive, useFlowSession } from "api/flows";
import { Flow, useFlows } from "api/flows";
import { CheckRounded, DownloadOutlined, ErrorOutlined, FileDownloadOutlined, HourglassBottomOutlined, HourglassTopOutlined, OpenInNew, Replay } from "@mui/icons-material";
import Chip from "@mui/joy/Chip";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import CircularProgress from "@mui/joy/CircularProgress";
import { saveDataToExcel } from "utils/excelUtils";
import { trackDownload } from "api/tracking";
import { useUser } from "api/users";
import { useSubscription } from "api/billing";
import { Link } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { prettifyNumber } from "../../utils/formattingUtils";

import excel from '../../assets/images/excel-format.png';
import csv from '../../assets/images/csv-format.png';
import ppt from '../../assets/images/ppt-format.png';
import pdf from '../../assets/images/pdf-format.png';

const FORMATS_IMAGES = {
    xlsx: excel,
    csv: csv,
    pptx: ppt,
    pdf: pdf,
}

const FileDownloadModal = ({ sessionId, loadCompleted }: {
    sessionId: string,
    loadCompleted: (session: FlowSession) => void
}) => {
    const { isLoading, data: session } = useFlowSession(sessionId, {
        onSuccess: (data: FlowSession) => {
            loadCompleted(data)
        }
    })

    return (
        <React.Fragment>
            <Modal
                aria-labelledby="close-modal-title"
                open={true}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        minWidth: 300,
                        borderRadius: 'md',
                        p: 3,
                    }}
                >
                    <Stack direction='row'
                        spacing={1}
                        alignItems='center'>
                        <CircularProgress
                            color="primary"
                            size="md"
                            value={25}
                            variant="soft"
                        />
                        <Typography
                            component="h2"
                            id="close-modal-title"
                            level="h4"
                            textColor="inherit"
                            fontWeight="lg"
                        >
                            Preparing file...
                        </Typography>
                    </Stack>
                </Sheet>
            </Modal>
        </React.Fragment>
    );
}


export const SessionsPage = () => {
    const navigate = useNavigate()
    const [from, setFrom] = useState(dayjs().add(-30, 'days').startOf('day').unix())
    const [to, setTo] = useState(dayjs().endOf('day').unix())
    const [dateFormat, setDateFormat] = useState('MMM DD, YYYY')
    const [autorefresh, setAutorefresh] = useState(false)
    const [hts, setHts] = useState(dayjs().unix())
    const [sessionId, setSessionId] = useState<string | null>(null)
    const [flowsBasicDetails, setFlowsBasicDetails] = useState<{ [key: string]: { icon: string } } | null>(null)

    const { data: archive, isLoading, hasNextPage, fetchNextPage } = useArchive({ from, to, autorefresh, hts })
    const { data: user, isLoading: loadingUser } = useUser()
    const { data: flows, isLoading: isLoadingFlows } = useFlows()
    const { data: subscription, isLoading: loadingSubscription } = useSubscription()

    const [dataLength, setDataLength] = useState(0);

    const statuses: {
        [key: string]: {
            color: 'success' | 'warning' | 'danger'
            icon: ReactNode
        }
    } = {
        'success': {
            color: 'success',
            icon: <CheckRounded sx={{ fontSize: "18px" }} />
        },
        'pending': {
            color: 'warning',
            icon: <HourglassTopOutlined sx={{ fontSize: "18px" }} />
        },
        'error': {
            color: 'danger',
            icon: <ErrorOutlined sx={{ fontSize: "18px" }} />
        }
    }

    useEffect(() => {
        if (!!archive && !!archive?.pages) {
            setDataLength(
                archive.pages.reduce((counter, page) => {
                    return counter + page.archive.length;
                }, 0)
            );
        }
    }, [archive]);

    useEffect(() => {
        if (flows) {
            let flowsParams: any = {};
            flows.forEach(el => {
                flowsParams[el.id] = {
                    icon: el.logo
                }
            });

            setFlowsBasicDetails(flowsParams)
        }
    }, [flows]);

    const startDownload = (sId: string) => {
        setSessionId(sId)
    }

    const getExportFromat = (fileName: any): 'xlsx' | 'pdf' | 'pptx' | 'csv' => {
        const fileNameParts = fileName ? fileName.split('.')[1] : null;

        return fileNameParts ?? 'xlsx'
    }

    if (loadingUser || loadingSubscription || !user || !subscription)
        return (<></>)


    return (
        <>
            {
                sessionId ?
                    <FileDownloadModal
                        sessionId={sessionId}
                        loadCompleted={(session) => {
                            setSessionId(null)
                            saveDataToExcel(session.fileName)
                        }} />
                    : <></>
            }
            <Box
                sx={{
                    display: 'flex',
                    my: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2">Downloads</Typography>
            </Box>
            <Stack
                direction='row'
                spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        closeOnSelect={true}
                        label="From"
                        value={dayjs.unix(from ?? dayjs().startOf('day').unix())}
                        format={dateFormat}
                        onChange={(newValue) => {
                            setFrom(newValue?.unix() ?? dayjs().unix())
                            setHts(dayjs().unix())
                            return
                        }}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="To"
                        closeOnSelect={true}
                        value={dayjs.unix(to ?? dayjs().startOf('day').unix())}
                        format={dateFormat}
                        onChange={(newValue) => {
                            setTo(newValue?.unix() ?? dayjs().unix())
                            setHts(dayjs().unix())
                            return
                        }}
                    />
                </LocalizationProvider>
            </Stack>
            <Box>
                <InfiniteScroll
                    dataLength={dataLength}
                    next={fetchNextPage}
                    hasMore={!!hasNextPage}
                    loader={<Sheet key="loader" variant="outlined" color="warning"><HourglassBottomOutlined /> Loading history...</Sheet>}
                >
                    {archive?.pages?.map((pageResult, i) => {
                        return (
                            <Box key={i}>
                                {pageResult.archive.map((item) => {
                                    const status = item.finish ? item.error ? 'error' : 'success' : 'pending'
                                    return (
                                        <Sheet
                                            variant="outlined"
                                            color="neutral"
                                            key={item.sessionId}
                                            sx={{
                                                p: 2,
                                                my: 2,
                                                borderRadius: 'sm'
                                            }}
                                        >
                                            <Stack
                                                direction='row'
                                                spacing={2}
                                                display='flex'
                                                flexWrap='wrap'
                                                justifyContent='space-evenly'
                                                alignItems='center'
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        justifyContent: "center",
                                                        flex: '0 0 auto',
                                                        width: '110px'
                                                    }}
                                                >
                                                    <Box
                                                        component="img"
                                                        sx={{
                                                            height: 44,
                                                            width: 44,
                                                            marginBottom: "8px"
                                                        }}
                                                        src={FORMATS_IMAGES[getExportFromat(item.fileName)]}
                                                    />
                                                    <Chip
                                                        variant="soft"
                                                        size="sm"
                                                        sx={{
                                                            borderRadius: "4px",
                                                            padding: "4px 8px",
                                                            minWidth: "98px",
                                                            "& .MuiChip-label": {
                                                                flexGrow: 0
                                                            }
                                                        }}
                                                        startDecorator={
                                                            statuses[status].icon
                                                        }
                                                        color={
                                                            statuses[status].color
                                                        }

                                                    >
                                                        {item.finish ? 'Success' : 'In progress'}
                                                    </Chip>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        flex: '1 0 0%'
                                                    }}>
                                                    <Stack direction='column'
                                                        spacing={1}>
                                                        <Link
                                                            href={item.link}
                                                            target="_blank"
                                                            level="body-md"
                                                            // title={item.title}
                                                            sx={{
                                                                textDecoration: "underline",
                                                                textDecorationStyle: "dashed",
                                                                textUnderlinePosition: "under",
                                                                textDecorationSkipInk: "none",
                                                                position: "relative",
                                                                display: "block",
                                                                "&:hover": {
                                                                    opacity: 0.8,
                                                                    "& .open-in-new": {
                                                                        display: "inline-block"
                                                                    }
                                                                }
                                                            }}

                                                        >
                                                            {item.title}
                                                            <OpenInNew
                                                                className="open-in-new"
                                                                sx={{
                                                                    fontSize: "16px",
                                                                    marginLeft: "4px",
                                                                    display: "none",
                                                                    position: "absolute",
                                                                    bottom: 0,
                                                                    height: "20px"
                                                                }}
                                                            />
                                                        </Link>
                                                        <Typography level="body-sm">{prettifyNumber(item.count, false)} records</Typography>
                                                    </Stack>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        maxWidth: '50px',
                                                        display: "flex"
                                                    }}
                                                >
                                                    {flowsBasicDetails && flowsBasicDetails[item.flowId].icon ?
                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                height: 40,
                                                                width: 40
                                                            }}
                                                            src={flowsBasicDetails[item.flowId].icon}
                                                        /> : null
                                                    }
                                                </Box>
                                                <Stack
                                                    direction='column'
                                                    spacing={1}
                                                    sx={{
                                                        maxWidth: '300px'
                                                    }}
                                                >
                                                    <Typography noWrap level="body-md">{item.name}</Typography>
                                                    <Stack direction={"row"}>
                                                        <Typography level='body-md' color="neutral">
                                                            {dayjs.unix(item.start).format('YYYY MMM DD hh:mm:ss A')}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                                {
                                                    item.fileName ?
                                                        <Button
                                                            color="primary"
                                                            variant="soft"
                                                            startDecorator={
                                                                <FileDownloadOutlined />
                                                            }
                                                            onClick={
                                                                () => {
                                                                    trackDownload(user, subscription, item, item.count, 'downloads')
                                                                    return startDownload(item.sessionId)
                                                                }
                                                            }
                                                        >
                                                            Download
                                                        </Button>
                                                        : <Box sx={{
                                                            width: '100px'
                                                        }}>&nbsp;</Box>
                                                }
                                                {status == "error" ?
                                                    <Button
                                                        color="neutral"
                                                        variant="outlined"
                                                        startDecorator={
                                                            <Replay />
                                                        }
                                                        onClick={
                                                            () => {
                                                                navigate(`/apps/setup/${item.flowId}?link=${item.link}`)
                                                            }
                                                        }
                                                    >
                                                        Repeat
                                                    </Button> : null
                                                }
                                            </Stack>
                                        </Sheet>
                                    )
                                })}
                            </Box>
                        )
                    })}
                </InfiniteScroll>
            </Box>
        </>)
}