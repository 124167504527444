import axios from 'axios';
import { saveAs } from 'file-saver';
import { last } from 'lodash';
import { exportsArchivePath } from '../api/common';

export const saveDataToExcel = async (filePath: string) => {
    const fileUrl = `${exportsArchivePath}${filePath}`
    try {
        const linkParts = filePath.split('/');
        const fileName: string = last(linkParts) ?? "Export Comments";
    
        // Fetch the file
        const response = await axios.get(fileUrl, {
          responseType: 'blob',
        });
    
        // Use FileSaver to save the file
        saveAs(response.data, fileName);
      } catch (error) {
        console.error('Error downloading the file:', error);
      }
}
